import React from 'react';

import CategoryPage from '../../components/CategoryPage';

const Pregnant = () => (
  <CategoryPage
    title="Grávida"
    totalItems={9}
    s3Path={`portfolio/pregnant/`}
    internalPath={`/gravida/`}
    nextPath={`/recem-nascido`}
    nextLabel="Recém Nascido"
    prevPath={`/gravida`}
    prevLabel="Grávida"
  />
)

export default Pregnant;
